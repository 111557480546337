/* ANIAMTIONS */
@keyframes fadeOverlayIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.75;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

/* SPINNER */
.spinner {
	display: inline-block;
	border: 0.3rem solid hsl(var(--twc-neutral-950));
	border-radius: 50%;
	border-top-color: hsl(var(--twc-primary-500));
	animation: spin 1s ease-in-out infinite;
}

html.light .spinner {
	border: 0.3rem solid hsl(var(--twc-neutral-900));
	border-top-color: hsl(var(--twc-primary-500));
}

/* TEMP FIX */
@media (max-width: 767px) {
	div.MuiContainer-root.MuiContainer-maxWidthSm {
		box-shadow: none !important;
	}
}

/* MISC */
.krece-input-floating-label:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 2000px hsl(var(--twc-neutral-925)) inset !important;
	-webkit-text-fill-color: hsl(var(--twc-neutral-100)) !important;
}

.krece-input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 2000px hsl(var(--twc-neutral-950)) inset !important;
	-webkit-text-fill-color: hsl(var(--twc-neutral-100)) !important;
}

html.light .krece-input-floating-label:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 2000px #f8f9f9 inset !important;
	-webkit-text-fill-color: hsl(var(--twc-neutral-100)) !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
	filter: invert(1) !important;
}

input[type='date']::-moz-calendar-picker-indicator {
	filter: invert(1) !important;
}

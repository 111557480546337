* {
	font-family:
		'Inter',
		ui-sans-serif,
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		Trebuchet MS,
		Segoe UI,
		Helvetica Neue,
		Arial,
		Noto Sans,
		sans-serif,
		Apple Color Emoji,
		Segoe UI Emoji,
		Segoe UI Symbol,
		Noto Color Emoji;
	min-width: 0;
	outline: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
	font-size: 16px;
	background-color: hsl(var(--twc-background));
	color: hsl(var(--twc-neutral-100));
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

::-webkit-scrollbar-track {
	background-color: hsl(var(--twc-foreground));
	border-radius: 0.5rem;
}

@media (max-width: 768px) {
	::-webkit-scrollbar-track {
		background-color: hsl(var(--twc-background));
	}

	::-webkit-scrollbar-corner {
		background-color: hsl(var(--twc-background));
	}
}

::-webkit-scrollbar-thumb {
	background-color: hsl(var(--twc-neutral-700));
	border-radius: 2rem;
	cursor: pointer;
}

::-webkit-scrollbar-corner {
	background-color: hsl(var(--twc-foreground));
}

::-webkit-scrollbar-thumb:hover {
	background-color: hsl(var(--twc-neutral-500));
}

html.light ::-webkit-scrollbar-thumb {
	background-color: hsl(var(--twc-neutral-800)) !important;
}

html.light ::-webkit-scrollbar-track {
	background-color: hsl(var(--twc-background)) !important;
	border-radius: 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* IE, Edge, Firefox */
.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}